import { Card } from "antd";
import React from "react";

const Overview = ({ pikettResults }) => {
  return pikettResults.map((member, index) => (
    <div key={index}>
      <Card
        title={member.name}
      >{`Pikett Einsätze: ${member.pikettDays.length}`}</Card>
    </div>
  ));
};

export default Overview;
