import { Alert, Button } from "antd";
import PikettWizzard from "components/pikett-wizzard/pikett-wizzard.component";
import React, { useEffect, useState } from "react";
import { NagerDateService } from "services/nager-date.service";
import "./introduction.scss";

const Introduction = () => {
  const [holidaysCH, setHolidaysCH] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [showWizzard, setShowWizzard] = useState(false);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const nagerDateService = new NagerDateService();
    nagerDateService
      .getHolidaysForCountry("CH", currentYear)
      .then((data) => {
        setHolidaysCH(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return !isLoading ? (
    <div className="intorduction-cointainer">
      <Alert
        message="Faire verteilung von Pikettdienst für die Schweiz"
        description={`Mit diesem Tool hast du die möglichkeit den Pikettdienst für dein Team fair zu verteilen.
        Im ersten Schritt erfasst du die Namen deiner Temammitlgieder auf welche die Einsätze verteilt werden sollen.
        Wähle danach den gwünschten Kanton um die Feiertage mit einzubringen.
        Die Einsätze werden für jedes Wochenende und für die offiziellen Feiertage generiert. Einsätze werden jeweils für ein ganzes Wochenende/Feiertag am Stück generiert.`}
        type="info"
        showIcon
      />
      {!showWizzard ? (
        <Button type="primary" onClick={() => setShowWizzard(true)}>
          Tool starten
        </Button>
      ) : null}
      {showWizzard ? <PikettWizzard holidaysCH={holidaysCH} /> : null}
    </div>
  ) : null;
};

export default Introduction;
