import { InputNumber, Select } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useState } from "react";
import "./canton-year-select.scss";

const CantonYearSelect = ({ onSelectionDone }) => {
  const [selection, setSelection] = useState({
    year: undefined,
    canton: undefined,
    withChristmas: false,
  });

  const selectOptions = [
    { label: "AG", value: "AG" },
    { label: "AR", value: "AR" },
    { label: "AI", value: "AI" },
    { label: "BL", value: "BL" },
    { label: "BS", value: "BS" },
    { label: "BE", value: "BE" },
    { label: "FR", value: "FR" },
    { label: "GE", value: "GE" },
    { label: "GL", value: "GL" },
    { label: "GR", value: "GR" },
    { label: "JU", value: "JU" },
    { label: "LU", value: "LU" },
    { label: "NE", value: "NE" },
    { label: "NW", value: "NW" },
    { label: "OW", value: "OW" },
    { label: "SG", value: "SG" },
    { label: "SH", value: "SH" },
    { label: "SZ", value: "SZ" },
    { label: "SO", value: "SO" },
    { label: "TG", value: "TG" },
    { label: "TI", value: "TI" },
    { label: "UR", value: "UR" },
    { label: "VS", value: "VS" },
    { label: "VD", value: "VD" },
    { label: "ZG", value: "ZG" },
    { label: "ZH", value: "ZH" },
  ];

  const changeSelection = (value, identifier) => {
    const newSelection = { ...selection, [identifier]: value };
    setSelection(newSelection);
    onSelectionDone(newSelection);
  };

  return (
    <div className="selection-container">
      <InputNumber
        className="year-input"
        placeholder="Jahr"
        value={selection ? selection.year : undefined}
        onChange={(e) => changeSelection(e, "year")}
      />
      <Select
        className="canton-input"
        options={selectOptions}
        value={selection ? selection.canton : undefined}
        onChange={(e) => changeSelection(e, "canton")}
      ></Select>
      <Checkbox
        onChange={(e) => changeSelection(e.target.checked, "withChristmas")}
      >
        inkl. 25./26. Dezember
      </Checkbox>
    </div>
  );
};

export default CantonYearSelect;
