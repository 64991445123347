import { AxiosBaseService } from "./axios-base.service";

export class NagerDateService extends AxiosBaseService {
  constructor() {
    super("https://date.nager.at/api/v3/PublicHolidays");
    this.getHolidaysForCountry = async (countryCode, year) => {
      return await this.instance
        .get(`/${year}/${countryCode}`)
        .then((response) => {
          for (const holidayEntry of response.data) {
            delete holidayEntry.launchYear;
            delete holidayEntry.types;
            if (holidayEntry.counties) {
              // Damit Kantone von Nager-Date ohne 'CH-' dargestellt werden
              holidayEntry.counties = holidayEntry.counties.map((c) =>
                c.replace("CH-", "")
              );
            }
          }
          return response.data;
        })
        .catch(this.errorHandling);
    };
  }
}
