import React, { useState } from "react";
import { Steps, Button } from "antd";
import "./pikett-wizzard.scss";
import EditTeam from "./edit-team/edit-team.component";
import CantonYearSelect from "./canton-year-select/canton-year-select.component";
import { NagerDateService } from "services/nager-date.service";
import { PikettInstructorService } from "services/pikett-instructor.service";
import Overview from "./overview/overview.component";

const PikettWizzard = ({ holidaysCH }) => {
  const [current, setCurrent] = useState(0);
  const [currentTeam, setCurrentTeam] = useState([]);
  const [holidaysConfig, setHolidaysConfig] = useState(undefined);
  const [rawIcals, setRawIcals] = useState(undefined);
  const [results, setResults] = useState(undefined);
  const { Step } = Steps;

  const steps = [
    {
      title: "Team erfassen",
      content: (
        <EditTeam
          currentTeam={currentTeam}
          onTeamChange={(newTeam) => {
            setCurrentTeam([...newTeam]);
          }}
        />
      ),
    },
    {
      title: "Kanton & Jahr auswählen",
      content: (
        <CantonYearSelect
          onSelectionDone={(configObject) => {
            setHolidaysConfig(configObject);
          }}
        />
      ),
    },
    {
      title: "Einteilung runterladen",
      content: <Overview pikettResults={results} />,
    },
  ];

  const isNextButtonDisabled = () => {
    if (current === 0) {
      return !(currentTeam.length > 0);
    }
    if (current === 1) {
      if (holidaysConfig) return false;
      return true;
    }
  };

  const generatePikettDays = async () => {
    const nagerDateService = new NagerDateService();
    const holidays = await nagerDateService.getHolidaysForCountry(
      "CH",
      holidaysConfig.year
    );
    const matchingHolidays = holidays.filter((holiday) => {
      return (
        holiday.global === true ||
        holiday.counties.includes(holidaysConfig.canton)
      );
    });
    const pikettInstructorService = new PikettInstructorService();
    const pikettResults = pikettInstructorService.generatePikettPlan(
      currentTeam,
      matchingHolidays,
      holidaysConfig.year,
      holidaysConfig.withChristmas
    );
    setRawIcals(pikettResults.rawIcal);
    setResults(pikettResults.results);
  };

  // Function to download data to a file
  const download = (data, filename, type) => {
    var file = new Blob([data], { type: type });
    // @ts-ignore
    if (window.navigator.msSaveOrOpenBlob)
      // IE10+
      // @ts-ignore
      window.navigator.msSaveOrOpenBlob(file, filename);
    else {
      // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  };

  const next = async () => {
    if (current === 1) {
      await generatePikettDays();
    }
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <Steps current={current} className="pikett-wizzard-container">
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => next()}
            disabled={isNextButtonDisabled()}
          >
            Weiter
          </Button>
        )}
        {current === steps.length - 1 && (
          <>
            <Button
              type="primary"
              onClick={() =>
                download(rawIcals, "pikett-calendar.ics", "text/calendar")
              }
            >
              Download
            </Button>
            <Button onClick={generatePikettDays}>Neu zuteilen</Button>
          </>
        )}

        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Zurück
          </Button>
        )}
      </div>
    </>
  );
};

export default PikettWizzard;
