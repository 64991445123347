import {
  endOfYear,
  eachWeekendOfYear,
  eachWeekendOfInterval,
  format,
  add,
} from "date-fns";

export class PikettInstructorService {
  constructor() {
    this.generatePikettPlan = (team, holidays, year, withChristmas) => {
      const shuffledTeam = shuflleTeam(team);
      const allWeekends = getAllWeekends(year);
      let holidaysFiltered = holidays;
      if (!withChristmas) {
        holidaysFiltered = holidays.filter((holiday) => {
          return (
            holiday.localName !== "Weihnachten" &&
            holiday.localName !== "Stephanstag"
          );
        });
      }
      const filteredWeekends = cleanHolidaysOutOfWeekends(
        holidaysFiltered,
        allWeekends
      );
      filteredWeekends.push(getBridgeDayAuffahrt(holidaysFiltered));
      for (const holiday of holidaysFiltered) {
        filteredWeekends.push(holiday.date);
      }
      filteredWeekends.sort(function (a, b) {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });
      const pikettResults = mapPikettOnTeam(shuffledTeam, filteredWeekends);
      return { results: pikettResults, rawIcal: getEvents(pikettResults) };
    };
  }
}

const shuflleTeam = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const getAllWeekends = (year) => {
  const currentYear = new Date().getFullYear();
  let allJsDates;
  if (currentYear === year) {
    const fromDate = new Date();
    const toDate = endOfYear(fromDate);
    allJsDates = eachWeekendOfInterval({ start: fromDate, end: toDate });
  } else {
    allJsDates = eachWeekendOfYear(new Date(`${year}-01-01`));
  }
  const weekends = allJsDates.map((date) => {
    return format(date, "yyyy-MM-dd");
  });
  return weekends;
};

const cleanHolidaysOutOfWeekends = (holidays, weekends) => {
  let cleanedWeekends = weekends;
  for (const holiday of holidays) {
    cleanedWeekends = cleanedWeekends.filter(
      (dateString) => dateString !== holiday.date
    );
  }
  return cleanedWeekends;
};

const getBridgeDayAuffahrt = (holidays) => {
  const auffahrt = holidays.filter(
    (holiday) => holiday.localName === "Auffahrt"
  );
  if (auffahrt.length > 0)
    return format(add(new Date(auffahrt[0].date), { days: 1 }), "yyyy-MM-dd");
  return;
};

const mapPikettOnTeam = (teamArray, piketDaysArray) => {
  const daysToAssort = piketDaysArray;
  const results = teamArray.map((teamMember) => {
    return { name: teamMember, pikettDays: [] };
  });
  let memberIndex = 0;
  while (daysToAssort.length > 0) {
    const dayToAssort = daysToAssort.shift();
    results[memberIndex].pikettDays.push(dayToAssort);
    const nextDay = format(
      add(new Date(dayToAssort), { days: 1 }),
      "yyyy-MM-dd"
    );
    if (daysToAssort[0] === nextDay) continue;
    else {
      //find next candidate
      // eslint-disable-next-line no-loop-func
      const followUpCandidates = results.filter((member) => {
        return (
          member.pikettDays.length <= results[memberIndex].pikettDays.length
        );
      });
      if (followUpCandidates.length > 0) {
        //find one with least pikett
        followUpCandidates.sort((a, b) => {
          return a.pikettDays.length - b.pikettDays.length;
        });
        const nextMember = results.findIndex((member) => {
          return member.name === followUpCandidates[0].name;
        });
        memberIndex = nextMember;
      } else {
        //needs to do more pikett
        continue;
      }
    }
  }
  return results;
};

const getEvents = (pikettResults) => {
  let events = "";
  for (const member of pikettResults) {
    for (const pikettDay of member.pikettDays) {
      const dayString = pikettDay.split("-");
      const newIcalEvent = `\nBEGIN:VEVENT
DTSTART:${dayString[0]}${dayString[1]}${dayString[2]}T000000GMT+0100
DTEND:${dayString[0]}${dayString[1]}${dayString[2]}T235900GMT+0100
DESCRIPTION:Pikettdienst ${member.name} ${pikettDay}
SUMMARY:Pikettdienst ${member.name} ${pikettDay}
END:VEVENT`;

      events = events + newIcalEvent;
    }
  }
  const rawIcal = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//bobbin v0.1//NONSGML iCal Writer//EN
CALSCALE:GREGORIAN
METHOD:PUBLISH${events}
END:VCALENDAR`;

  return rawIcal;
};
