import "./header.scss";
import { CalendarOutlined } from "@ant-design/icons";

import React from "react";

const Header = () => {
  return (
    <div className="header-container">
      {"Faire Pikettdienst Einteilung "}
      <CalendarOutlined />
    </div>
  );
};

export default Header;
