import Axios from "axios";
import { toast } from "react-toastify";

export class AxiosBaseService {
  constructor(baseUrl) {
    this.instance = Axios.create({
      timeout: 15000,
      baseURL: baseUrl,
    });
    this.responseBody = (response) => {
      return response.data;
    };
    this.errorHandling = (error) => {
      toast.error(error.message);
      console.error(error);
      throw error;
    };
  }
}
