import { Button, Card, Input } from "antd";
import React, { useState } from "react";
import "./edit-team.scss";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const EditTeam = ({ currentTeam, onTeamChange }) => {
  const [newName, setNewName] = useState("");

  const addNewMember = () => {
    if (newName) {
      currentTeam.push(newName);
      onTeamChange(currentTeam);
      setNewName("");
    }
  };

  const deleteMember = (index) => {
    const updatedTeam = currentTeam.filter(
      (element) => element !== currentTeam[index]
    );
    onTeamChange(updatedTeam);
  };

  return (
    <div>
      <Card title="Team Mitglied hinzufügen" style={{ width: 300 }}>
        <Input
          placeholder="Name"
          name="name"
          value={newName}
          onChange={(e) => {
            setNewName(e.target.value);
          }}
        />
        <Button icon={<PlusOutlined />} onClick={addNewMember}>
          hinzufügen
        </Button>
      </Card>
      {currentTeam.length > 0
        ? currentTeam.map((member, index) => (
            <div key={index} className="single-memeber">
              <span className="member-name">{member}</span>
              <Button
                icon={<MinusOutlined />}
                onClick={() => {
                  deleteMember(index);
                }}
              />
            </div>
          ))
        : null}
    </div>
  );
};

export default EditTeam;
