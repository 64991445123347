import "./App.scss";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Layout } from "antd";
import React from "react";
import Header from "components/header/header.component";
import Introduction from "components/introduction/introduction.component";

function App() {
  return (
    <div className="App">
      <Layout>
        <Layout.Header>
          <Header />
        </Layout.Header>
        <Layout.Content>
          <div className="content-container">
            <ToastContainer
              autoClose={3000}
              theme="light"
              position="top-center"
            />
            <Introduction />
          </div>
        </Layout.Content>
        <Layout.Footer>
          <div className="footer-container"></div>
        </Layout.Footer>
      </Layout>
    </div>
  );
}

export default App;
